import * as React from "react";

import Layout from "../components/layout";

const NotFoundPage = () => (
  <Layout>
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage;

export function Head( {location} ) {

  const title = "404: Not Found";
  const description = "You've reached an dead end... the sadness.";
  const siteURL = "https://newwestfreemasons.ca"

  return (
      <>
        <title>
          {title}
        </title>
        <meta name="description" content={description} />
        <meta property="og:site_name" content="Perfection Lodge No. 75, New Westminster" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:locale" content="ca_EN" />
        <meta property="og:type" content="website" />
        <meta
            property="og:url"
            content={`${siteURL}${location.pathname}/`}
        />
        <meta property="og:image" content="https://newwestfreemasons.ca/logo.png" />
        <link rel="canonical" href={`${siteURL}${location.pathname}/`} />
      </>
  )
}